@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.About {
	@include defaultPage();
	padding-top: 5em;
	@include backgroundImage();
	&-content {
		padding: 2rem 0.5rem;
		@include contain(900px);
	}

	&-heading {
		font-family: Georgia, 'Times New Roman', Times, serif;
		font-weight: bold;
		color: $red-dark;
		text-align: left;
		padding-left: 2rem;
		font-size: 1.1em;
		margin: 0;
		margin-bottom: 1rem;
	}

	&-text {
		font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
		margin: 2rem;
		text-align: left;
		color: #000;
		line-height: 1.4;
		margin-top: 0.5em;
		margin-bottom: 2rem;
	}

	&-ddLogo {
		width: 8em;
		height: auto;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		margin: 0 auto;
	}

	&-footer {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
	}

	&-copyright {
		font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
		font-size: 0.875em;
		line-height: 6;
		color: #000;
		text-align: center;
	}
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.LandingPage {
	@include defaultPage();
	padding: 3rem 0.5rem;
	.LandingPage-logo {
		position: absolute;
		z-index: 10;
		bottom: 0.5em;
		right: 1.5em;
		width: 7.5em;
		height: 2.25em;
		background-color: transparent;
		background-image: url('../../assets/images/logo-cgl-dark.svg');
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: bottom center;
		&:hover,
		&:focus,
		&:active {
			background-color: transparent;
		}
	}
	&-header {
		@include flex('center');
		padding: 1rem;
		height: 9rem;
	}

	&-ddLogo {
		width: 11.125em;;
		height: auto;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

	&-content {
		margin-top: 8.5em;
	}

	&-heading {
		font-family: Georgia, 'Times New Roman', Times, serif;
		font-weight: bold;
		color: $red-dark;
		margin: 0;
		font-size: 1.8em;
		text-transform: uppercase;
	}

	&-startBtn {
		margin: 1.5rem auto;
		width: 4.8em;
		height: auto;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

	&-resetCacheBtn {
		position: absolute;
		bottom: 1em;
		top: 1em;
		height: 3rem;
		width: 3rem;
		color: white;
		background-color: $red;
		padding: 0.125em 0.25em;
		border-radius: 50%;
		@include flex('space-around', 'center');
	}

	&-infoBtn {
		font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
		color: #000;
	}

	.LandingPage-availabilityStatementLink {
		position: absolute;
		left: 5em;
		bottom: 0.5em;
		font-size: 1em;
		text-decoration: underline;
		color: black;
		&:hover,
		&:active,
		&:visited {
			color: black;
		}
	}
}



@media (max-aspect-ratio: '9/16') {
	.LandingPage {
		justify-content: space-between;
		.LandingPage-content {
			margin: auto;
		}
		// .LandingPage-availabilityStatementLink {
		// 	position: relative;
		// 	left: auto;
		// 	bottom: auto;
		// 	margin-top: 1em;
		// 	padding-bottom: 0;
		// }
	}
}
@media (max-width: 530px) {
	.LandingPage {
		.LandingPage-logo {
			bottom: 2.5em;
		}
	}
}


// @media (max-aspect-ratio: '2/3') {
// 	.LandingPage {
// 		.LandingPage-logo {
// 			bottom: 2.5em;
// 		}
// 	}
// }


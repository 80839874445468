@import '../styles/fonts';
@import '../styles/variables';
@import '../styles/mixins';
@import '../styles/animations';
.App {
	text-align: center;
	&--stats {
		height: 100vh;
	}
	.App-cookiebotBtn {
		position: fixed;
		bottom: 0em;
		left: 0.2em;
		height: 4rem;
		width: 4rem;
		color: transparent;
		background-image: url('../assets/images/icon-cookies.svg');
		background-size: contain;
		@include no-select();
	}
	#CookiebotWidget {
		display: none;
	}
}

.pointer {
	cursor: pointer;
}

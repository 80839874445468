@import 'styles/fonts';
@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/animations';
html {
	font-size: 16px;
}

body {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1em;
	font-weight: normal;
	margin: 0;
	padding: 0;
	color: #000;
	background-color: $background;
	touch-action: manipulation; // disables double-tap gestur -> no click delay by the browser
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: none;
}

* {
	box-sizing: border-box;
}

button {
	background-image: none;
	-webkit-tap-highlight-color: transparent;
	@include no-select();
	&:focus {
		outline: 0;
	}
	&::-moz-focus-inner {
		border: 0;
	}
}

ul,
ol {
	padding-inline-start: 1.5em;
	margin: 0;
}

input[type='submit'] {
	@include appearance(none);
}

#CookiebotWidget {
	display: none !important;
}

@media (max-aspect-ratio: '9/16') {
	html {
		font-size: calc(16 * ((100vw / 375)));
	}
}

@media (min-aspect-ratio: '9/16') {
	html {
		font-size: calc(16 * ((100vh / 640)));
	}
}

@media (max-width: 320px) {
	html {
		font-size: 16px;
	}
}
@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Quiz {
	height: 100%;
	@include flex('space-between', 'center', 'column');
	flex-grow: 1;
	&--paused {
		.Quiz-answer {
			cursor: not-allowed;
		}
	}

	&-header {
		position: fixed;
		left: 0;
		top: 6em;
		padding: 1em;
		@include contain(32em);
		text-align: center;
		z-index: 1;
		color: #fff;
		background-color: $red-dark;
		@include flex('flex-start', 'center');
		
	}

	&-body {
		position: relative;
		margin-top: 2.625em;
		padding: 1em;
		z-index: 0;
		@include flex('space-between', 'center', 'column');
		@include contain(32em);
		flex-grow: 1;
		width: 100%;
	}

	&-question {
		position: relative;
		font-size: 1em;
		line-height: 1.2;
		text-align: left;
		color: #fff;
		padding: 0 1em;
		p {
			display: inline;
			margin: 0;
			font-size: 1.126em;
		}

		&--left {
			text-align: left;
			padding: 0.25em 1.25em 0.5em 1.25em;
		}
	}


	&-instructions {
		margin-top: 0.5em;
		text-align: left;
		
		span {
			font-size: 1.126em;
			font-weight: bold;
		}
	}

	&-image {
		width: 100%;
		height: 15em;
		background-size: 100% auto;
		background-position: bottom center;
		background-repeat: no-repeat;
	}

	&-answers {
		width: 100%;
		@include flex('flex-start', 'center', 'column');
		flex-grow: 1;
	}

	&-image + &-answers {
		padding-top: 0;
	}

	&-answer {
		width: 100%;
		padding: 1em;
		margin: 0.5em;
		background-color: $beige-dark2;
		color: #000;
		border-radius: 5px;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		p {
			display: inline;
			margin: 0;
		}

		&.selected {
			background-color: darken($beige-dark2, 15%);
		}
		&.animateCorrect {
			color: $text-light;
			background-color: $success;
			-webkit-animation: bounce 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1
				forwards;
			-webkit-animation-fill-mode: forwards;
			animation: bounce 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1 forwards;
			animation-fill-mode: forwards;
		}
		&.animateWrong {
			color: $text-light;
			background-color: $failure;
			-webkit-animation: wobble 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1
				forwards;
			-webkit-animation-fill-mode: forwards;
			animation: wobble 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1 forwards;
			animation-fill-mode: forwards;
		}
		&.correct {
			color: $text-light;
			background-color: $success;
		}
		&.wrong {
			color: $text-light;
			background-color: $failure;
		}
	}
}

@media (max-aspect-ratio: '7/8') {
	.Quiz {
		&-header {
			top: 4em;
			padding-top: 0.75em;
			padding-bottom: 0.75em;
		}
		&-question {
			p {
				font-size: 1em;
			}
		}
		&-instructions {
			margin-top: 0.25em;
			span {
				font-size: 0.9em;
			}
		}
		&-body {
			margin-top: 2em;
			padding: 1em;
		}
		&-answer {
			padding: 0.75em 0.5em;
			p {
				display: block;
				font-size: 0.9em;
				line-height: 1.1;
			}
		}
	}
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.BackButton {
	position: absolute;
	top: 2em;
	left: 2em;
	width: auto;
	height: 2.25em;
	z-index: 2;
	flex-basis: 1em;
	svg {
		height: 100%;
	}
}



@media (max-aspect-ratio: '7/8') {
	.BackButton {
		top: 1.2em;
		left: 0.8em;
		height: 1.5em;
	}
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.RoomOverview {
	@include defaultPage();
	padding: 1em 0.4em 0 0.4em;
	
	text-align: center;
	&-header {
		@include flex('center');
		padding: 1rem;
		height: 9rem;
		margin-bottom: 1.4em;
	}
	&-ddLogo {
		width: 11.125em;;
		height: auto;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		cursor: pointer;
	}

	&-chooseLanguage {
		display: none;
		position: absolute;
		top: 1.7em;
		right: 1em;
		width: 3em;
		height: 3em;
		background-size: contain;
		background-position: top right;
		background-repeat: no-repeat;
		&.lang--dk {
			background-image: url('../../assets/images/en-flag.svg');
		}
		&.lang--en {
			background-image: url('../../assets/images/dk-flag.svg');
		}
	}

	&-title {
		font-family: Georgia, 'Times New Roman', Times, serif;
		font-weight: bold;
		color: $red-dark;
		margin: 0;
		font-size: 1.25em;
		text-transform: uppercase;
	}

	&-description {
		@include contain(25em);
		margin: 1rem auto 1rem auto;
		text-align: left;
		color: #000;
		p {
			font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
			line-height: 1.1;
			text-align: center;
		}
	}

	&-rooms {
		@include contain(17.3em);
		text-align: center;
		margin: 0 auto;
	}

	&-Btn {
		display: inline-block;
		width: 17.3em;
		height: 2.7em;
		border-radius: 3.125em;
		padding: 0 1.43em;
		margin-bottom: 1em;
		border: $red solid 0.2em;
		background-color: $red;
		background-image: url('../../assets/images/icon-next-white.svg');
		background-size: auto 1em;
		background-position: center right 1em;
		background-repeat: no-repeat;
		@include flex('flex-start', 'center');
		span {
			color: #fff;
		}
	}

	&-otherGames {
		@include contain(25em);
		margin: 0 auto;
		p {
			font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
			line-height: 1.1;
			text-align: center;
			color: #000;
		}
	}

	&-otherGamesBtn {
		width: 17.3em;
		height: 2.7em;
		border-radius: 3.125em;
		padding: 0 1.43em;
		margin: 0 auto;
		border: $red solid 0.2em;
		background-image: url('../../assets/images/icon-next.svg');
		background-size: auto 1em;
		background-position: center right 1em;
		background-repeat: no-repeat;
		color: #000;
		text-decoration: none;
		@include flex('flex-start', 'center');

	}
}

@media (min-aspect-ratio: '7/8') {
	.RoomOverview {
	@include backgroundImage();
	}
}
@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Popup {
	visibility: hidden;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: $text-dark;
	background-color: rgba($background, 0.65);
	z-index: 20;
	opacity: 0;
	@include transition('all', 0.33s, 'ease-in-out');
	&--show {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
		.Popup-body {
			@include translate(0px, 0px);
		}
	}

	&-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 5em;
	}
	@include contain(900px);
	&-body {
		@include transition('all', 0.3s, 'ease-in-out');
		@include translate(0px, -100px);
		margin: 0 auto;
		padding: 1em;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
		@media (min-width: 1024px) {
			border-radius: 0 0 9px 9px;
		}
	}
	&-wrap {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 2em;
	}

	&-close {
		position: absolute;
		top: 0;
		right: 0;
		width: 3.5em;
		height: 3.5em;
		background-image: url('../../assets/images/icon-x.svg');
		background-size: 1.75 auto;
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
	}

	&-title {
		font-family: 'EB Garamond';
		font-size: 1.5em;
		font-weight: 700;
		text-transform: uppercase;
		padding: 0 1em 1em 1em;
	}

	&-text {
		font-size: 1.5625em;
		line-height: 1.2;
		padding-bottom: 2em;

		span.Popup-animal {
			display: inline-block;
			width: 4em;
			height: 4em;
			background-size: contain;
			background-repeat: no-repeat;
			&.novice {
				background-image: url('../../assets/images/highscore/novice.svg');
			}
			&.amateur {
				background-image: url('../../assets/images/highscore/amateur.svg');
			}
			&.apprentice {
				background-image: url('../../assets/images/highscore/apprentice.svg');
			}
			&.expert {
				background-image: url('../../assets/images/highscore/expert.svg');
			}
			&.master {
				background-image: url('../../assets/images/highscore/master.svg');
			}
			&.grand-master {
				background-image: url('../../assets/images/highscore/grand-master.svg');
			}
		}
	}

	&-points {
		font-size: 1.5625em;
		font-weight: 900;
	}

	&-feedback {
		width: 100%;
		padding: 2em;
		color: white;
		text-align: left;
		p {
			text-align: center;
			font-size: 1.5625em;
			margin-top: 0;
		}
		> div {
			position: relative;
			font-size: 1.125em;
			padding-left: 1em;
			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0.45em;
				width: 0.5em;
				height: 0.5em;
				border-radius: 0.5em;
				background-color: $beige2;
			}
		}
	}

	&-image {
		width: 100%;
		img {
			width: 100%;
		}
	}

	&-buttons {
		text-align: center;
		padding: 0 1em;
		@include flex(space-between, center);
	}
	&-button {
		display: inline-block;
		font-size: 2em;
		font-weight: 700;
		text-transform: uppercase;
		color: $red;
		font-family: 'ED Garamond';
		padding: 0.25em 1em;
		border-radius: 0.5em;
	}
	&-iconPlay {
		width: 3em;
		height: 2em;
		margin-top: 1em;
	}
	&-iconX {
		width: 40px;
		margin-top: 1em;
		margin-bottom: 2em;
	}
	&-endNav {
		@include flex(space-between, center);
		.Popup-iconIgen,
		.Popup-iconNy {
			width: 8em;
		}
	}
}

/* Streak + newPointsTier */

.Popup.Popup--streak,
.Popup.Popup--newPointsTier {
	.Popup-body {
		position: relative;
		background-color: $background;
		@include flex('center', 'center');
	}
	.Popup-wrap {
		padding-bottom: 3.3em;
		.Popup-title {
			font-family: Georgia, 'Times New Roman', Times, serif;
			font-size: 1.4em;
			padding: 0;
			color: $red-dark;
			text-align: left;
		}
	}
	.Popup-text {
		font-size: 1rem;
		margin-top: 1em;
		color: #000;
		text-align: left;
		padding: 0;
		.red {
			color: $red-dark;
			font-weight: bold;
		}
	}
	.Popup-tierbox {
		margin-top: 1rem;
		@include flex('space-around', 'center');
		.Popup-tier {
			font-family: Georgia, 'Times New Roman', Times, serif;
			font-size: 2em;
			font-weight: bold;
			padding: 0;
			color: $red-dark;
			text-align: left;
			margin: 0.2em 0;
		}
	}
	.Popup-buttons {
		padding: 0;
		margin-top: 0;
		display: block;
		text-align: center;
		.Popup-button {
			display: none;
			padding: 0.5em 0em;
			width: 8em;
		}
	}
	.Popup-points {
		display: inline-block;
		font-family: Georgia, 'Times New Roman', Times, serif;
		font-size: 3em;
		font-weight: bold;
		padding: 0;
		color: $red-dark;
		text-align: left;
		margin: 0;
		position: absolute;
		top: 2rem;
		right: 3rem;
	}
}

/* Challenge completed */
.Popup.Popup--challengeCompleted {
	.Popup-body {
		min-height: 100%;
		@include flex('center', 'center');
		padding: 0;
		background-color: transparent;
		box-shadow: none;
		height: 100%;
	}
	.Popup-buttons {
		height: 100%;
		width: 100%;
		@include flex('center', 'center', 'column');
	}
}

/* Room completed */
.Popup.Popup--roomCompleted {
	background-color: $background;
	@media (min-aspect-ratio: '7/8') {
		@include backgroundImage();
	}
	overflow: auto;
	@include contain(600px);
	.Popup-body {
		position: relative;
		width: 100%;
		height: auto;
		box-shadow: none;
		padding-bottom: 1em;
		.Popup-text {
			margin: 0;
			margin-top: 1em;
		}
	}
	.Popup-innerCard {
		position: relative;
		background-color: #fff;
		height: auto;
		min-height: 8em;
		margin-top: 1em;
		padding: 0.5em;
		@include box-shadow(0, 0.1876em, 0.25em, 0, rgba(#000000, 0.16));
		border-radius: 5px;
		padding-bottom: 3.5em;
	}
	.Popup-tierbox {
		@include flex('space-around', 'center');
		.Popup-tier {
			display: inline-block;
			font-family: Georgia, 'Times New Roman', Times, serif;
			font-size: 2em;
			font-weight: bold;
			padding: 0;
			color: $red-dark;
			text-align: left;
			margin: 0.2em 0;
		}
	}
	.Popup-wrap {
		padding: 0;
	}
	.Popup-title {
		font-family: Georgia, 'Times New Roman', Times, serif;
		font-size: 1.4em;
		padding: 0;
		color: $red-dark;
		text-align: left;
	}
	.Popup-text {
		font-size: 1rem;
		margin-top: 1em;
		color: #000;
		text-align: left;
		padding: 0;
		margin: 0.5em;
		p {
			margin: 0;
			margin-bottom: 1em;
		}
	}
}

.Ribbon {
	&-newPointsTier,
	&-streak,
	&-roomCompleted {
		@include ribbon(#a5132a, #6b1b21);
	}
}

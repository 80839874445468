@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Room {
	@include defaultPage();
	height: auto;

	@media (min-aspect-ratio: '7/8') {
		.Room {
		@include backgroundImage();
		}
	}
}
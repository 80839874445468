@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Order {
	height: 100%;
	@include flex('space-between', 'center', 'column');
	flex-grow: 1;
	&--paused {
		.Order-item {
			cursor: not-allowed;
		}
	}

	&-header {
		position: fixed;
		left: 0;
		top: 6em;
		padding: 1em;
		@include contain(32em);
		text-align: center;
		z-index: 1;
		color: #fff;
		background-color: $red-dark;
		@include flex('center', 'flex-start', 'column');
	}

	&-question {
		position: relative;
		font-size: 1em;
		line-height: 1.2;
		text-align: left;
		color: #fff;
		padding: 0 1em;
		p {
			display: inline;
			margin: 0;
			font-size: 1.126em;
		}
	}

	&-boxes {
		width: 100%;
		margin: 1em 0 0.3em 0;
		padding-left: 1.4em;
		@include flex('center', 'center');
	}

	&-boxContainer {
		width: 3.8em;
		height: 2.3em;
		@include flex('center', 'center');
		&::after {
			content: '';
			width: 2em;
			height: 100%;
			background-image: url('../../../assets/images/icon-next-dark.svg');
			background-size: auto 0.75em;
			background-position: center center;
			background-repeat: no-repeat;
			margin: 0 0.3em;
			
		}
		&:nth-child(1) {
			margin-left: 0;
		}
		&:nth-last-child(1) {
			@include flex('left', 'center');
			.Order-boxText {
				width: 1.8em;
			}
			&::after {
				display: none;
			}
		}
		&--selected {
			.Order-boxText {
				background-color: darken($beige-dark2, 15%);
			}
		}
		&--placed {
			color: $text-light;
			.Order-boxText {
				background-color: $success;
			}
		}
		&--correct {
			color: $text-light;
			.Order-boxText {
				background-color: $success;
			}
		}
		&--wrong {
			color: $text-light;
			.Order-boxText {
				background-color: $failure;
			}
		}
	}
	&-boxText {
		padding: 0.5em 0;
		width: 100%;
		background-color: $beige-dark2;
		color: #fff;
		font-size: 1.126em;
		text-transform: uppercase;
		font-weight: bold;
		border-radius: 3px;
		p {
			display: inline;
			margin: 0;
		}
		@include no-select();
	}

	&-body {
		position: relative;
		margin-top: 2.625em;
		padding: 1em;
		z-index: 0;
		@include flex('space-between', 'center', 'column');
		@include contain(32em);
		flex-grow: 1;
		width: 100%;
	}

	&-items {
		width: 100%;
		flex-wrap: wrap;
		@include flex('flex-start', 'center', 'row');
	}

	&-itemWrap {
		width: 100%;
		position: relative;
	}

	&-item {
		width: calc(100% - 1em);
		padding: 1em;
		margin: 0.5rem;
		background-color: $beige-dark2;
		color: #000;
		border-radius: 5px;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		p {
			display: inline;
			margin: 0;
		}
		@include box-shadow(0, 0.1876em, 0.25em, 0, rgba(#000000, 0.16));
		@include transition(padding, 0.1s, linear);
		&--selected {
			background-color: darken($beige-dark2, 15%);
			color: #fff;
		}
		&--placed + .Order-itemNumber {
			color: $text-light;
			background-color: $success;
		}
		&--placed {
			padding-left: 3em;
		}

		&--correct {
			color: $text-light;
			background-color: $success;
			-webkit-animation: bounce 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1
				forwards;
			-webkit-animation-fill-mode: forwards;
			animation: bounce 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1 forwards;
			animation-fill-mode: forwards;
		}
		&--wrong {
			color: $text-light;
			background-color: $failure;
			-webkit-animation: wobble 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1
				forwards;
			-webkit-animation-fill-mode: forwards;
			animation: wobble 0.5s cubic-bezier(0.28, 0.84, 0.42, 1) 0s 1 forwards;
			animation-fill-mode: forwards;
		}
	}

	&-itemNumber {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		padding: 1em 1em;
		margin: 0.5rem;
		border-radius: 5px 0 0 5px;
		@include transition(color, 0.1s, ease);
		color: transparent;
		height: calc(100% - 1em);
		@include flex(center, center);
	}
}

@media (max-aspect-ratio: '7/8') {
	.Order {
		&-header {
			top: 4em;
			padding-top: 0.75em;
			padding-bottom: 0.75em;
		}
		&-body {
			margin-top: 2em;
			padding: 1em;
		}
		&-question {
			p {
				font-size: 1em;
			}
		}
		&-boxContainer {
			height: 2em;
		}
		&-item {
			padding: 0.75em 0.5em;
			p {
				font-size: 0.9em;
			}
		}
	}
}
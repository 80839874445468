$border-radius: 0.25em;

/* Colors */
$background: #F1EAE2;

$beige2: #F9F7F4;
$beige-dark2: #C8AA8C;

$red: #E1232D;
$red-dark: #780028;

$text-dark: #000;
$text-light: #fff;

$success: #5ca39c;
$failure: #d67c1c;
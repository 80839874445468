@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Challenge {
	position: relative;
	@include flex('center');
	flex-grow: 1;
	&-background {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}

	&-header {
		position: relative;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 6em;
		text-align: center;
		z-index: 2;
		padding: 0 2.6em;
		@include flex('center');
		@include contain(37.5em);
	}

	&-nav {
		@include flex('space-around', 'center');
		height: 3.19em;
		margin: 2em 0;
		padding: 0 1em;
		border-radius: 5px;
		background: $beige2;
		flex-grow: 1;
	}

	&-challengeStatus {
		font-family: Georgia, 'Times New Roman', Times, serif;
		font-weight: bold;
		font-size: 2.5em;
		line-height: 0;
		color: $beige-dark2;
		&--selected {
			color: $red-dark;
		}
		&--completed {
			color: $red;
		}
	}

	&-body {
		position: relative;
		width: 100%;
		min-height: 100%;
		top: 0;
		left: 0;
		padding: 0 0 0.75em 0;
		overflow-x: hidden;
		&.hide {
			opacity: 0;
		}
		margin-top: 3.8em;
	}
}

@media (max-aspect-ratio: '7/8') {
	.Challenge {
		&-header {
			width: 100%;
			height: 4em;
			padding: 0 2.6em;
			background-color: $background;
		}
		&-nav {
			height: 2.5em;
			margin: 0.75em 0;
		}
	
		&-challengeStatus {
			font-size: 1.8em;
		}
		&-body {
			margin-top: 1.8em;
		}
	}
}